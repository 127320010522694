import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    /* Colors */
    --black: #1F1D1E;
    --white: #F9F9F9;
    --grey: #8D8D8D;
    --grey-dark: #322F2F;
    --grey-medium: #D8D8D8;
    --grey-light: #9b9b9b;
    
    --beige-light: #F3E9D1;
    --beige-dark: #847662;
    
    --blue-main: #1780D3;
    --blue-dark: #005090;
    --blue-light: #00B1EB;
    --blue-pale: #7BAACF;
    --blue-hover-btn: #4DC9F1;
    
    --hero-overlay: #204461b5;
    --dev-overlay: #0d5289;
    --copy-overlay: var(--blue-pale);
    --contact-overlay: var(--blue-hover-btn);
    
    scroll-behavior: smooth;
  }
  html {
    scrollbar-color: var(--pale) var(--grey);

  }
  body {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    background-color: var(--white);
    padding: 0;
    margin: 0;
    
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 22px;
    }

  }

  * {
    box-sizing: border-box;
  }

  /* Scrollbar Styles */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background: var(--grey-medium);
    border: unset;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--grey-light);
    border-radius: 2px;
    border: unset;
  }
  
  a {
    text-decoration: none;
    color: var(--black);
  }
  address {
    font-style: unset;
  }

/* Typography */
  h1, .h1 {
    font-size: 96px;
    font-weight: 600;
    line-height: 104px;
    letter-spacing: 0;
    font-family: 'Playfair Display', serif;

    @media (max-width: 768px) {
      font-size: 56px;
      line-height: 64px;
    }
  }
  h2, .h2 {
    font-size: 64px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: 0;
    font-family: 'Playfair Display', serif;

    @media (max-width: 768px) {
      font-size: 42px;
      line-height: 50px;
    }
  }
  h3, .h3 {
    font-size: 48px;
    font-weight: 800;
    line-height: 56px;
    letter-spacing: 0;
    font-family: 'Playfair Display', serif;

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 40px;
    }
  }
  h4, .h4 {
    font-size: 36px;
    line-height: 44px;
    font-weight: 700;
    
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  h5, .h5 {
    font-size: 24px;
    line-height: 32px; 
    font-weight: 700;
    
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
  .text-small {
    letter-spacing: 0.32px
    font-family: "Encode Sans Condensed", sans-serif;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0;
    }
  }
  .error {
    display: block;
  }

/* Buttons */
  button, .button {
    padding: 16px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 1.4px;
    background-color: var(--white);
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    &:focus-visible {
      outline: 1px solid var(--black);
    }
    &.primary {
      background-color: var(--blue-main);
      color: var(--white);
      &:hover {
        background-color: var(--blue-hover-btn);  
      }
    }
    &.secondary{
      background-color: var(--white);
      color: var(--blue-main);
      border: 1px solid var(--blue-dark);
      &:hover {  
        color: var(--blue-hover-btn);  
        border-color: var(--blue-hover-btn);
      }
    }
    $.button-big {
      padding: 42px 24px;
      font-size: 24px;
      line-height: 32px;
    }
    &.button-bold {
      color: var(--black);
      padding: 16px 8px;
      font-weight: 300;
      &:hover {
        text-shadow: 0 0 0.75px;
      }
    }
    .mobile-menu-text {
      padding: 0;
      font-size: 24px;
      line-height: 32px;
      font-weight: 300;
      &:hover {
        text-shadow: 0 0 0.75px;
      }
    }
    .text-underline {
      padding: 0;
      font-size: 14px;
      line-height: 22px;
      
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 100%;
        height: 2px;
        background: var(--blue-main);
        transition: all 0.5s ease;
      }
      &:hover {
        &::after {
          right: 0;
        }
      }
    }
  }
  #main-section {
    padding-top: 80px;
  }
  .divider {
    padding: 15px 0;
    margin: 0 50px;
    border-top: 1px solid var(--black);
    @media (max-width: 1023px) {
      margin: 0 25px;
    }
  }
  input[type="checkbox"] {
    width: 0;
    height: 0;
    margin-right: 35px;
    position: relative;
    
    &:before {
      content: '';
      display: block;
      background: var(--white);
      border-radius: 2px;
      border: 2px solid var(--blue-light);
      width: 20px;
      height: 20px;
    }
    &:checked {
      &:before {
        content: '';
        background: var(--blue-light);
      }
      &:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 8px;
        width: 5px;
        height: 12px;
        border-right: 3px solid var(--white);
        border-bottom: 3px solid var(--white);
        transform: rotate(45deg)
      }
    }
  }
  
  fieldset {
    padding: 0;
    margin: 0;
    border: unset;
    background: unset;
    
    .field {
      margin-bottom: 25px;
      &_2cols {
        @media (min-width: 1024px) {
          display: flex;
          gap: 20px;
          > .field {
            flex: 1 1 auto;
            width: 100%;
          }
        }
      }
      &.regulation {
        display: flex;
        flex-direction: row-reverse;
        label {
          width: 100%;
        }
      }
      label {
        display: block;
      }
      input:not([type="checkbox"]), textarea {
        width: 100%;
        padding: 15px;
        border: 1px solid var(--grey);
        
        &:focus-visible {
          outline: unset;
          border: 1px solid var(--black);
        }
      }
    }
    [type="submit"] {
      display: block;
      width: 100%;
      margin : 10px 0 0;
      @media (min-width: 1024px) {
        max-width: calc(50% - 10px);
        margin : 0 0 0 auto;
      }
    }
  }
`;

export default GlobalStyles;